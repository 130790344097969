import request from '@/utils/request'

export function add(data) {
  return request({
    url: '/crm/tCustDepositWithdraw',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: '/crm/tCustDepositWithdraw/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: '/crm/tCustDepositWithdraw',
    method: 'put',
    data
  })
}

export function modifyPayStatus(data) {
  return request({
    url: '/crm/tCustDepositWithdraw/modifyPayStatus ',
    method: 'post',
    data
  })
}

export function modifyStatus(data) {
  return request({
    url: '/crm/tCustDepositWithdraw/modifyStatus',
    method: 'get',
    params: data
  })
}
export function audit(data) {
  return request({
    url: '/crm/tCustDepositWithdraw/audit',
    method: 'get',
    params: data
  })
}

export function tPayFeeConfig(data) {
  return request({
    url: '/crm/tPayFeeConfig',
    method: 'get',
    params: data
  })
}

// 出入金列表支付方式下拉菜单
export function getPayTypeSelectList(data) {
  return request({
    url: '/crm/getPayTypes',
    method: 'get',
    params: data
  })
}


